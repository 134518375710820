interface TypeProps{}

const Difference:React.FC<TypeProps>=() =>{

    return(
        <div className="flex justify-center">
            <div className="w-screen lg:w-[60%] bg-gradient-to-b from-[#36698D] via-[#36698D] to-[#c4e2f9] ">
                <p className="text-white text-center text-[24px] text-size-title-diff pt-8">
                คุณหรือคนรอบตัวเล่นพนันแบบไหน ?
                </p>
                <p className="text-white text-center text-[14px] sm:text-[16px] lg:text-[18px]">
                ข้อแตกต่างระหว่าง<br />
                เล่นพนันเพื่อเข้าสังคม <span className="text-[#FF7E7E]">กับ</span> เล่นพนันจนเป็นปัญหา
                </p>
                <div className="text-[14px] sm:text-[16px] lg:text-[18px]">

                    <div className="flex flex-col items-center">
                        
                            <div className="bg-[#79A5CA] w-[60%] sm:w-[40%] lg:w-[40%] rounded-xl py-4 text-white text-center mt-4 z-10">
                                เล่นพนันเพื่อเข้าสังคมหรือความบันเทิง
                            </div>
                            <div className="bg-[#C4E2F9] pt-8 rounded-xl w-[80%] sm:w-[50%] lg:w-[60%] p-4 sm:px-8   relative -top-6">
                                <li >มักเล่นพนันกับเพื่อน</li>
                                <li className="mt-2">ไม่เสี่ยงเล่นพนันเกินกว่าศักยภาพในการจ่ายของตนเอง</li>
                                <li className="mt-2">ยอมรับได้ว่าการเสียพนันเป็นส่วนหนึ่งของการเสี่ยง</li>
                                <li className="mt-2">เมื่อเสียพนันก็ไม่ใส่ใจที่จะเล่นพนันต่อเพื่อให้ได้เงินคืน</li>
                                <li className="mt-2">การเล่นพนันไม่มีผลกระทบต่อการงาน วิถีชีวิต หรือ ความสัมพันธ์กับครอบครัวและคนรอบข้าง</li>
                            </div>
                        

                        <div className="bg-[#79A5CA]  w-[60%] sm:w-[40%] lg:w-[40%] rounded-xl p-4 text-white text-center mt-2 z-10">
                        เล่นพนันจนเป็นปัญหา
                        </div>
                        <div className="bg-[#C4E2F9] pt-8 rounded-xl w-[80%] sm:w-[50%] lg:w-[60%] p-4 sm:px-8  relative -top-6">
                            <li>มักเล่นพนันเพื่อเสพความตื่นเต้น หรือเพื่อหลีกหนีปัญหา</li>
                            <li className="mt-2">เล่นพนันแบบไม่มีวินัย ไม่วางข้อกำหนดให้ตัวเองว่าจะหยุดเล่นตอนไหน</li>
                            <li className="mt-2">เสี่ยงโดยไม่จำเป็น เล่นเกินศักยภาพในการจ่ายของตนเอง</li>
                            <li className="mt-2">เล่นได้ก็อยากเล่นต่อเพราะหวังจะได้มากขึ้น เล่นเสียก็ไม่เลิกเพราะหวังจะแก้มือ เอาเงินคืน</li>
                            <li className="mt-2">โดยรวมมักจะเสียพนันเสมอและจำนวนไม่น้อยกลายเป็นผู้มีปัญหาจากการพนัน</li>
                        </div>
                        
                    </div>


                </div>

            </div>
        </div>

        
    );

};
export default Difference;
