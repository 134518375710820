import {document} from '../assets/common';
interface TypeProps{

}

const From: React.FC<TypeProps> =() => {
    const redirectToFrom = () => {
        window.open('https://form-gambling.thaieconomap.com/', '_blank');
    };    

    return (
        <div className='flex justify-center '>
            <div className='flex flex-col items-center bg-[#953c48] p-6 w-full justify-center lg:w-[60%] ' >
                <p className='mb-4 text-white text-size-title-from text-center'>คุณกำลังประสบปัญหาจากการพนันหรือไม่ ?</p>
                <div className='bg-[#F1DCE6] rounded-xl flex p-4 shadow-md cursor-pointer hover:scale-105 hover:bg-[#f0cfdf] w-fit ' onClick={redirectToFrom}>
                    <p>ลองทำแบบประเมิน</p>
                    <img src={document} alt="" className='w-[18px] ml-3' />
                </div>
            </div>    
        </div>
        

        
    );
};
export default From;