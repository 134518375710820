interface TypeProps{

}

const Marketing: React.FC<TypeProps> =() => {
    return (
        <div className="flex justify-center">
            <div className='text-center py-[20px] bg-[#EBF5FD] w-[100%] lg:w-[60%]'>
                <p className=' text-[24px] md:text-[34px]'>
                การตลาดลุ้นโชค
                </p>
                <p className='mt-[22px] text-[14px] md:text-[18px]'>
                แอบแฝงใช้การพนันในรูปแบบต่าง ๆ มาทำการตลาด<br />
                ให้กับสินค้าหรือบริการในลักษณะที่อาจเข้าข่ายเป็นการพนัน 
                </p>

            </div>
        </div>
        
    );
};
export default Marketing;







    