import { useEffect } from 'react';
import React from 'react';
import { avenues, avenuesdata } from '../assets/common';

interface TypeProps {}

const Avenues: React.FC<TypeProps> = () => {
    const offlineData = avenuesdata.filter((item) => item.type === 'offline');
    const onlineData = avenuesdata.filter((item) => item.type === 'online');

    useEffect(() => {
        document.documentElement.classList.add('js');
    
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/taos@1.0.5/dist/taos.js';
        document.head.appendChild(script);
    
        return () => {
            document.documentElement.classList.remove('js');
            document.head.removeChild(script);
        };
        }, []);
    

    return (
        <div className='flex justify-center w-[100%] h-[360px] md:h-[700px] lg:h-[650px] sm:h-[600px]'>
            <div className="w-[100vh] w-bg lg:w-[60%] bg-cover bg-center flex justify-around items-end px-10 no-p  md:p-0 " style={{ backgroundImage: `url(${avenues})`, }}>   
                <div className='w-[50%] pb-[6.5rem] sm:pb-[12rem] lg:pb-[11rem] '>
                    <p className="text-[24px] sm:text-[34px] mb-4 lg:mb-10 sm:mb-16 lg:pl-10 sm:pl-12 p-title">
                    ช่องทางการ <br /> เล่นพนัน
                    </p>
                    <div className="delay-[100ms] duration-[700ms] taos:translate-y-[200px] taos:opacity-0" data-taos-offset="100">
                        <p className='text-center pr-12 md:text-[24px]'>Offline</p>
                        <div className='flex flex-col items-center pr-12 '>
                            {offlineData.map((item) => (
                                <div
                                key={item.key}
                                className="bg-[#C0DEF5] flex flex-col items-center justify-center w-[60px] sm:w-[90px] md:w-[130px] lg:w-[120px] rounded-tr-xl rounded-bl-xl py-2 sm:py-6 m-1"
                                >
                                <img src={item.src} alt={item.name} className='lg:h-[40px] md:h-[30px]'/>
                                <p className="text-[10px] lg:text-[15px] md:text-[15px] lg:pt-1 md:pt-2">{item.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-[60%] lg:w-[60%]  pb-[6.5rem] lg:pb-[11rem] sm:pb-[12rem] pr-3 lg:pr-12 sm:pr-10 delay-[100ms] duration-[700ms] taos:translate-y-[200px] taos:opacity-0" data-taos-offset="100">
                    <p className='text-center md:text-[24px]'>Online</p>
                    <div className='flex flex-wrap justify-center items-center '>
                        {onlineData.map((item) => (
                            <div
                            key={item.key}
                            className="bg-[#C0DEF5] flex flex-col items-center justify-center w-[60px] sm:w-[90px] md:w-[130px] lg:w-[120px] rounded-tr-xl rounded-bl-xl py-2 sm:py-6 m-1"
                            >
                            <img src={item.src} alt={item.name} className='h-[20px] lg:h-[40px] md:h-[30px]'/>
                            <p className="text-[10px] lg:text-[15px] md:text-[15px] lg:pt-1 md:pt-2">{item.name}</p>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        
            
        </div>
    );
};

export default Avenues;
