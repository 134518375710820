import { useEffect } from "react";
import { bghead,sadwomen, card } from "../assets/common";
import "./Styles.css";
interface TypeProps {}

const WhatIsGambling: React.FC<TypeProps> = () => {
  useEffect(() => {
    document.documentElement.classList.add("js");

    const script = document.createElement("script");
    script.src = "https://unpkg.com/taos@1.0.5/dist/taos.js";
    document.head.appendChild(script);

    return () => {
      document.documentElement.classList.remove("js");
      document.head.removeChild(script);
    };
  }, []);
  return (
    <>
        <div className="relative ">
            <div className="flex justify-center">
            <img src={bghead} alt="" className="w-[100%] lg:w-[60%]" />
            </div>
            <div className="z-20 relative -mt-24 mb-20 lg:-mt-60 md:-mt-52">
            <p className="text-center text-[22px] md:text-[42px] lg:text-[52px]">
                คุณกำลัง
                <span className="text-[#D35565] text-[26px] md:text-[46px] lg:text-[56px]">
                ประสบปัญหา
                </span>
                <br />
                จาก
                <span className="text-[#D35565] text-[26px] md:text-[46px] lg:text-[56px]">
                การพนัน
                </span>
                หรือไม่ ?
            </p>
            </div>
        </div>
        <div className="flex justify-center ">
            <div className="lg:w-[40%] w-[80%]  md:w-[60%]">
                <div className="md:mt-10">
                <div className="flex justify-end card">
                    <img src={card} alt="" className="pb-20 lg:pb-0 z-20 -mt-[4rem] lg:w-[20%] lg:mr-[10%] lg:-mt-[16rem] md:w-[30%] md:mr-[8%] md:-mt-[5rem]" />
                    <img src={sadwomen} alt="" className="sadwomen pt-10 z-20 -mt-20 lg:w-[30%] lg:-mt-[8rem] md:w-[30%] md:-mt-[rem]" />
                </div>
                <div className="flex justify-center items-center">
                    <p className="text-[24px] md:text-[34px] text-center z-50 -mt-24 lg:mb-10 md:mb-10 no-pd">
                    การพนันคืออะไร ?
                    </p>
                </div>
                </div>
                <div
                className="space flex flex-col items-center  "
                >
                <div className="bg-[#C4E2F9] text-[14px] rounded-xl p-5 indent-6 z-40 -mt-7 ">
                    <p className="text-[14px] md:text-[18px] lg:text-[18px]">
                    การพนัน (Gambling)
                    คือการเอาเงินทองทรัพย์สินหรือของมีค่าอ่ืนไปวางเดิมพันในกิจกรรมท่ีมีความเสี่ยงมีผลลัพธ์ไ์ม่แน่นอนว่าจะชนะหรือแพ้โดยคาดหวังว่าจะชนะและได้รางวัลเป็นสิ่งตอบแทน
                    </p>
                </div>
                </div>
            </div>
        </div>
    </>
  );
};
export default WhatIsGambling;
