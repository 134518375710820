import {signal} from '../assets/common/index';
interface TypeProps {

}

const Signal:React.FC<TypeProps>=() =>{
    return(
        <div>
            <div className=' relative'>
                <div className='flex justify-center '>
                    <div className="w-screen lg:w-[60%] h-full  bg-gradient-to-b from-[#F1DCE6] to-[#D35565] py-6 ">
                        <p className="text-[24px] text-center">
                        สัญญาณของการพนัน
                        </p>
                        <div className='flex justify-between'>
                            <div className='bg-[#FF7A00] h-[7px] w-[90px]'></div>
                            <p className="text-[24px] text-center">ท่ีเป็นปัญหา </p>
                            <div className='bg-[#FF7A00] h-[7px] w-[90px]'></div>
                        </div>

                        <div className='flex justify-center md:mb-10'>
                            <div className="bg-white rounded-xl list-disc mt-6 mx-6 px-10 py-8 lg:w-[60%] text-[14px] sm:text-[16px] lg:text-[18px]">
                                <li >เล่นการพนันนานกว่าที่คุณต้ังใจ</li>
                                <li className='mt-6'>พนันแบบทุ่มสุดตัว เล่นได้อยากได้เพิ่ม เล่นเสียพยายามเอาคืน</li>
                                <li className='mt-6'>ขาดเรียน / ขาดงานหรือไม่ทําตามสัญญา เนื่องจากการพนัน </li>
                                <li className='mt-6'>โกหกสมาชิกในครอบครัวหรือคนอื่น ๆ เก่ียวกับจํานวนเงินที่คุณเล่นการพนัน </li>
                                <li className='mt-6'>ไม่สนใจด้านอื่น ๆ ของชีวิต เนื่องจากใช้เวลาคิดถึงการพนัน</li>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center hide-el'>
                    <img src={signal} alt="" className="relative -mt-56 md:hidden lg:block lg:-mt-[20rem] w-[100%] lg:w-[60%]  "  />
                </div>
            </div>
            </div>
        
        

        
    );

};
export default Signal;
