
interface TypeProps{

}

const Footer: React.FC<TypeProps> =() => {
    return (
        <footer className="flex justify-center">
            <div className='bg-[#373737] p-4 h-[70px] flex flex-col items-center justify-center text-white w-full lg:w-[60%]'>
            <p className="text-[18px] font-semibold text-white">
                ติดพนัน เป็นโรค รักษาได้
            </p>
            <p className="text-zinc-500 text-[10px] font-light text-center">
             © สงวนลิขสิทธิ์ 2566 ศูนย์ศึกษาปัญหาการพนัน
            </p>
            </div>
      </footer>
        
    );
};
export default Footer;