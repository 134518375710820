import casino from './icon/casino.svg'
import computer from './icon/computer.svg';
import facebook from './icon/facebook.svg';
import home from './icon/home.svg';
import line from './icon/line.svg';
import phone from './icon/phone.svg';
import web from './icon/web.svg';
import tiktok from './icon/tiktok.svg';
const data = [
    {
        key: 1,
        type:'offline',
        name: 'บ่อนในชุมชน',
        src:home
    },
    {
        key: 2,
        type:'offline',
        name: 'คาสิโน',
        src:casino
    },
    {
        key: 3,
        type:'online',
        name: 'เว็บไซต์',
        src:web
    },
    {
        key: 4,
        type:'online',
        name: 'คอมพิวเตอร์',
        src:computer
    },
    {
        key: 5,
        type:'online',
        name: 'โทรศัพท์',
        src:phone
    },
    {
        key: 6,
        type:'online',
        name: 'เฟสบุ๊ค',
        src:facebook
    },
    {
        key: 7,
        type:'online',
        name: 'ไลน์',
        src:line
    },
    {
        key: 8,
        type:'online',
        name: 'ติ๊กตอก',
        src:tiktok
    },
    
];

export default data;
