const data = [
    {
        name:'โรงพยาบาลศรีธัญญา',
        tel: '02-5250981',
    },
    {
        name:'สถาบันจิตเวชศาสตร์สมเด็จเจ้าพระยา',
        tel: '02-4422500',
    },
    {
        name:'สถาบันกัลยาณ์ราชนครินทร์',
        tel: '02-4416100',
    },
    {
        name:'สถาบันสุขภาพจิตเด็กและวัยรุ่นราชนครินทร์',
        tel: '1323, 02-3548300',
    },
    {
        name:'สถาบันราชานุทูล',
        tel: '02-2488900',
    },
    {
        name:'โรงพยาบาลยุวประสารทไวทโยปถัมภ์ จ.สมุทรปราการ',
        tel: '02-3843381-3 ',
    },
    {
        name:'โรงพยาบาลสวนปรุง จ.เชียงใหม่',
        tel: '053-276750, 053-908500',
    },
    {
        name:'สถาบันพัฒนาการเด็กราชนครินทร์ จ.เชียงใหม่',
        tel: '053-890245',
    },
    {
        name:'โรงพยาบาลจิตเวชนครสวรรค์ราชนครินทร์',
        tel: '056-219444, 056-219400',
    },
    {
        name:'โรงพยาบาลพระศรีมหาโพธิ์ จ.อุบลราชธานี',
        tel: '045-352500',
    },
    {
        name:'โรงพยาบาลจิตเวชขอนแก่นราชนครินทร์',
        tel: '043-209999',
    },
    {
        name:'สถาบันพัฒนาการเด็กภาคตะวันออกเฉียงเหนือ จ.ขอนแก่น',
        tel: '043-910770-1',
    },
    {
        name:'โรงพยาบาลจิตเวชนครราชสีมาราชนครินทร์',
        tel: '044-342667',
    },
    {
        name:'โรงพยาบาลจิตเวชเลยราชนครินทร์',
        tel: '042-814895',
    },
    {
        name:'โรงพยาบาลจิตเวชนครพนมราชนครินทร์',
        tel: '042-593136',
    },
    {
        name:'โรงพยาบาลจิตเวชสระแก้วราชนครินทร์',
        tel: '037-262995',
    },
    {
        name:'โรงพยาบาลจิตเวชสงขลาราชนครินทร์',
        tel: '074-455000',
    },
    {
        name:'โรงพยาบาลสวนสราญรมย์ จ.สุราษฎร์ธานี',
        tel: '077-916500, 077-312146',
    },
    
    
];

export default data;
