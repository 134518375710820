import React, { useState, useEffect } from "react";
import {
  bgsecond,
  smbgsecond,
  typedata,
  arrowleft,
  arrowright,
} from "../assets/common";
import cx from "classnames";
import "./Styles.css";

interface TypeProps {}

const Type: React.FC<TypeProps> = () => {
  const [selectedKey, setSelectedKey] = useState(1);
  const [currentStep, setCurrentStep] = useState(0);
  const [autoSlide, setAutoSlide] = useState(true);
  const [autoSlideCount, setAutoSlideCount] = useState(0);

  const pushStep = (step: number) => {
    setCurrentStep(step);
  };

  const handleIncrement = () => {
    setSelectedKey((prevKey) => (prevKey < 3 ? prevKey + 1 : 1));
    setCurrentStep((prevKey) => (prevKey < 2 ? prevKey + 1 : 0));
  };

  const handleDecrement = () => {
    setSelectedKey((prevKey) => (prevKey > 1 ? prevKey - 1 : 3));
    setCurrentStep((prevKey) => (prevKey > 0 ? prevKey - 1 : 2));
  };

  const filteredData =
    selectedKey !== null
      ? typedata.filter((item) => item.key === selectedKey)
      : typedata;

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const startAutoSlide = () => {
      intervalId = setInterval(() => {
        handleIncrement();
        setAutoSlideCount((count) => count + 1);
      }, 2000);
    };

    if (autoSlide && autoSlideCount < 3) {
      startAutoSlide();
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [autoSlide, selectedKey, autoSlideCount]);

  return (
    <div className="relative mt-20 top">
      <div className="flex justify-center">
        <img
          src={bgsecond}
          alt=""
          className="sm:hidden block w-[100%] lg:w-[60%]"
        />
        <img
          src={smbgsecond}
          alt=""
          className="sm:block hidden w-[100%] lg:w-[60%] mt-6"
        />
      </div>
      <div className="absolute right-0 left-0 top-0 bottom-0 -mt-14 h-type">
        <p className="text-[24px] sm:text-[34px] text-center ">
          การพนันมีอะไรบ้าง
        </p>
        <div className="flex justify-center items-center mt-6 gap-x-3 sm:gap-x-8 gap  ">
          <button
            onClick={handleDecrement}
            className="btn w-[50px] h-[50px] bg-[#749BB1] rounded-full flex items-center justify-center "
          >
            <img src={arrowleft} alt="" />
          </button>
          <div className="w-[60%] sm:w-[40%] lg:w-[30%] card-type ">
            {filteredData.map((item) => (
              <div className="text-center " key={item.key}>
                <div className="bg-[#36698D] rounded-tr-3xl">
                  <div className="text-[20px] text-white flex justify-center items-center p-3 h-[90px]">
                    {item.topic}
                  </div>
                </div>
                <div className="bg-white rounded-bl-3xl p-6 lg:p-8 h-[170px] sm:h-[190px] drop-shadow-lg ">
                  <p className="text-[14px] sm:text-[18px] lg:text-[16px]">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <button
            onClick={handleIncrement}
            className="btn w-[50px] h-[50px] bg-[#749BB1] rounded-full flex items-center justify-center  "
          >
            <img src={arrowright} alt="" />
          </button>
        </div>
        <div className="flex justify-center mt-4 z-50">
          <div className="z-60">
            {[...Array(3)].map((_, i) => (
              <button
                key={i}
                type="button"
                className={cx("w-3 h-3 rounded-full mx-2 ", {
                  "bg-[#36698D]": i === currentStep,
                  "bg-white": i !== currentStep,
                })}
                onClick={() => pushStep(i)}
              ></button>
            ))}
          </div>
        </div>
      <div className="flex justify-center">
        {filteredData.map((item) => (
          <img
            key={item.key}
            src={item.src}
            alt=""
            className="relative -mt-28 lg:w-[60%] md:w-[100%] no"
          />
        ))}
      </div>
      </div>

    </div>
  );
};

export default Type;
