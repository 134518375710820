import { useState,useEffect } from "react";
import { agencydata } from "../assets/common";
interface TypeProps{

}

const Agency: React.FC<TypeProps> =() => {
    const [showAll, setShowAll] = useState(false);
    const visibleItems = showAll ? agencydata : agencydata.slice(0, 6);

    useEffect(() => {
        document.documentElement.classList.add('js');
    
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/taos@1.0.5/dist/taos.js';
        document.head.appendChild(script);
    
        return () => {
            document.documentElement.classList.remove('js');
            document.head.removeChild(script);
        };
        }, []);

    return (
        <div className="flex justify-center">
            <div className="text-center py-4 sm:py-10 lg:py-12 justify-center items-center flex bg-[#C4E2F9] bg-gradient-to-b from-[#F1DCE6] to-[#C4E2F9] w-full lg:w-[60%]">
                <div className='p-4 flex flex-col items-center justify-center'>
                    <p className="text-[24px] text-size-title-agen my-2">หน่วยงานให้บริการคำปรึกษา <br /> สุขภาพจิต</p>
                    <ul className="flex flex-col sm:flex-row sm:flex-wrap sm:justify-around sm:px-10 items-center text-start delay-[100ms] duration-[300ms] taos:translate-y-[200px] taos:opacity-0" data-taos-offset="100">
                    {visibleItems.map((hospital, index) => (
                        <li key={index} className="bg-white w-[70%] sm:w-[40%] my-2 rounded-xl p-4 h-[120px] card-hos">
                            <h2>{hospital.name}</h2>
                            <p className="text-gray-500 text-size-small-agen">โทร: {hospital.tel}</p>
                        </li>
                        ))}
                    </ul>
                    {agencydata.length > 5 && (
                        <div className="flex justify-center mt-4">
                        {showAll ? (
                            <button onClick={() => setShowAll(false)} className="text-white cursor-pointer bg-[#36698D] p-2 rounded-lg w-[80px] hover:scale-105 hover:bg-[#4783ad]">
                            ซ่อน
                            </button>
                        ) : (
                            <button onClick={() => setShowAll(true)} className="text-white cursor-pointer bg-[#36698D] p-2 rounded-lg  w-[80px] hover:scale-105 hover:bg-[#4783ad]">
                            ดูเพิ่มเติม
                            </button>
                        )}
                        </div>
                    )}
                </div>
            </div>
        </div>
       
        
    );
};
export default Agency;