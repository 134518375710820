import cardpics from './cardpics.svg';
import ballpics  from './ballpics.svg';
import lotterypics from './lotterypics.svg'; 
const data = [
    {
        key: 1,
        topic: 'พนันทายผลตัวเลขหรือสัญลักษณ์',
        description: 'การออกรางวัลด้วยวิธีการสุ่ม เช่น สลากกินแบ่งรัฐบาล สลากการกุศล หวยใต้ดิน หวยเลขท้ายสองสามตัว หวยสัตว์ หวยกล่อง หวยปิงปอง หวยเบอร์ตามย่านร้านตลาด',
        src:lotterypics
    },
    {
        key: 2,
        topic: 'พนันทายผลเหตุการณ์',
        description: 'การทายผลการแข่งกีฬา การให้สัตว์ต่อสู้หรือแข่งขัน และการละเล่นพื้นบ้าน เช่น พนันทายผลฟุตบอล บาสเกตบอล เทนนิส มวย แข่งม้า แข่งรถ แข่งเรือ ชนวัว ชนไก่ กัดปลา',
        src:ballpics
    },
    {
        key: 3,
        topic: 'เกมพนัน',
        description: 'การพนันที่ผู้ประกอบกิจการพนันกำหนดกฎกติกาการเล่น การแพ้-ชนะ และรางวัล เช่น บาคาร่า โป๊กเกอร์ ป๊อกเด้ง ไฮโล รูเล็ต สล็อตแมชชีน รวมถึงการพนันในงานวัด งานแฟร์',
        src:cardpics
    },
];

export default data;
