interface TypeProps {

}

const Problem:React.FC<TypeProps>=() =>{
    return(
        <div className="">
            <div className="text-center ">
                <p className="text-[24px] mt-6">
                จะแน่ใจได้อย่างไรว่า...
                </p>
                <p className="text-[#D35565]">
                ไม่มีปัญหาจากการพนัน ?
                </p>
            </div>
            <div className="flex justify-center mt-6 mb-12">
                <div className="bg-[#C4E2F9] w-[80%] sm:w-[60%] lg:w-[40%] p-6 rounded-xl ">
                    <p className="text-[14px] sm:text-[16px] lg:text-[18px]">
                    ปัญหาจะเริ่มเกิดขึ้น เมื่อผู้เล่นพนันเริ่ม <span className="text-[#D35565]">สูญเสียการควบคุมตนเอง </span>  ใช้เวลาและเงินทองเพื่อการพนันในลักษณะท่ีเป็นอันตรายต่อตนเองหรือคนรอบข้าง เช่น เล่นพนันบ่อยข้ึน ๆ เล่นหนักขึ้นเรื่อย ๆ ไม่สามารถหยุดเล่นพนันได้ หรือ บางคนตั้งใจว่าจะเลิกแล้ว แต่ก็ยังกลับไปเล่นซ้ำอีก หรือเร่ิมมีปัญหาหน้ีสินจากการพนัน
                    </p>
                </div>
            </div>
        </div>

        
    );

};
export default Problem;
