import WhatIsGambling from "./components/WhatIsGambling";
import Type from "./components/Type";
import Marketing from "./components/Marketing";
import Avenues from "./components/Avenues";
import Problem from "./components/Problem";
import Signal from "./components/Signal";
import Difference from "./components/Difference";
import Agency from "./components/Agency";
import Footer from "./components/Footer";
import From from "./components/From";

import "./App.css";
//import From from './components/From';

function App() {
  return (
    <div className="font-primary">
      
      <WhatIsGambling />
      <Type />
      <Marketing />
      <Avenues />
      <Difference />
      <Problem />
      <Signal />
      <From />
      <Agency />
      <Footer />
    </div>
  );
}

export default App;
